import React, { useState } from "react";
import bcrypt from "bcryptjs";
import styles from "./login.module.css";
import Chat from "../chat/Chat";
import { useNavigate } from "react-router-dom";
import axios from "axios";

interface LoginPopupProps {}

interface LoginRequestBody {
    name: string;
    email: string;
}

interface LoginResponseBody {
    sessionID: null;
    response: string;
    result: string;
    errormessage: string;
}

const LoginPopup = () => {
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [fullNameError, setFullNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const USERNAME = "AIuser";
    const PASSWORD_HASH = bcrypt.hashSync("tech@123", 10);
    const navigate = useNavigate();

    const validateEmail = (email: string) => {
        // Email format validation regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleLogin = (event: React.FormEvent) => {
        event.preventDefault();

        let isValid = true;

        setFullNameError("");
        setEmailError("");
        setUsernameError("");
        setPasswordError("");

        if (!fullName) {
            setFullNameError("*Full Name is required.");
            isValid = false;
        }

        if (!email) {
            setEmailError("*Email Address is required.");
            isValid = false;
        } else if (!validateEmail(email)) {
            setEmailError("*Please enter a valid email address.");
            isValid = false;
        }

        if (!username) {
            setUsernameError("*Username is required.");
            isValid = false;
        }else if (username !== USERNAME) {
            setUsernameError( "*Invalid username");
            isValid = false;
        }

        if (!password) {
            setPasswordError("*Password is required.");
            isValid = false;
        }else if (!bcrypt.compareSync(password, PASSWORD_HASH)) {
            setPasswordError("*Invalid password");
            isValid = false;
        }

        if (isValid && bcrypt.compareSync(password, PASSWORD_HASH)) {
            // Set cookies if they do and redirect to the home page
            document.cookie = `username=${username};`;
            document.cookie = `password=${password};`;
            navigate("chat");
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
        // // Check if Full Name, Email, username, and password are not empty
        // if (fullName && email && username && bcrypt.compareSync(password, PASSWORD_HASH)) {
        //     // Set cookies if they do and redirect to the home page
        //     document.cookie = `username=${username};`;
        //     document.cookie = `password=${password};`;
        //     LoginMethod(fullName,email)
        //     navigate("chat");
        // } else {
        //     alert("Full Name, Email, Username, and Password are required fields.");
        // }

        setIsLoggedIn(true);
    };

    async function LoginMethod(name: string, email: string): Promise<LoginResponseBody> {
        const url = "https://botchatapi.azurewebsites.net/api/Login/AddLogin";
        debugger;
        // const trimmedString = chatdescription.substring(1, chatdescription.length - 1);

        const requestBody: LoginRequestBody = {
            name: name,
            email: email
        };

        try {
            // axios.defaults.baseURL = 'http://54.172.137.151:81/api/Chat/AddChatHistory';
            // axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
            // axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
            // axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

            const response = await axios.post<LoginRequestBody, LoginResponseBody>(url, requestBody);
            return response;
        } catch (error: unknown) {
            console.error(error as Error);

            throw new Error("Failed to Login:");
        }
    }

    return (
        <div className={styles.Background}>
        <div className={styles.Logincontainer}>
                <form className={styles.formcontainer}>
                    <div>
                        <input type="text" onChange={e => setFullName(e.target.value)} placeholder="Full Name" className={styles.inputfield} />
                        <span className={styles.inputerror}>{fullNameError}</span>
                    </div>
                    <div>
                        <input type="email" onChange={e => setEmail(e.target.value)} placeholder="Email Address" className={styles.inputfield} />
                        <span className={styles.inputerror}>{emailError}</span>
                    </div>
                    <div>
                        <input type="text" onChange={e => setUsername(e.target.value)} placeholder="Username" className={styles.inputfield} />
                        <span className={styles.inputerror}>{usernameError}</span>
                    </div>
                    <div>
                        <input type="password" onChange={e => setPassword(e.target.value)} placeholder="Password" className={styles.inputfield} />
                        <span className={styles.inputerror}>{passwordError}</span>
                    </div>
                    <div>
                        <button type="submit" className={styles.button} onClick={handleLogin}>
                            Login
                        </button>
                    </div>
                </form>
            </div>
            </div>
    );
};

export default LoginPopup;
