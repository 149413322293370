import axios from "axios";

interface ChatRequestBody {
    guid: string;
    chat: string;
    rating: string;
    description: string;
    time: string;
    chatTime:string;
}

interface ChatResponseBody {
    sessionID: null;
    response: string;
    result: string;
    errormessage: string;
}

export async function UpdateHistory(
    guid: string,
    chatHistory: string,
    chatrating: string,
    chatdescription: string,
    chattime: string,
    QaTime:string
    
): Promise<ChatResponseBody> {
    const url = "https://botchatapi.azurewebsites.net/api/Chat/AddChatHistory";
    debugger;
    // const trimmedString = chatdescription.substring(1, chatdescription.length - 1);
    const trimmedString = chatdescription;

    const requestBody: ChatRequestBody = {
        guid: guid,
        chat: chatHistory,
        rating: chatrating,
        description: trimmedString,
        time: chattime,
        chatTime:QaTime
    };

    try {
        // axios.defaults.baseURL = 'http://54.172.137.151:81/api/Chat/AddChatHistory';
        // axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
        // axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        // axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

        const response = await axios.post<ChatRequestBody, ChatResponseBody>(url, requestBody);
        return response;
    } catch (error: unknown) {
        console.error(error as Error);

        throw new Error("Failed to update chat history:");
    }
}
