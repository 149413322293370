import { parseSupportingContentItem } from "./SupportingContentParser";

import styles from "./SupportingContent.module.css";
import { useState } from "react";
import ChatBubble from "../../components/ChatBubble/chatbubble";
interface Props {
    supportingContent: string[];
}

export const SupportingContent = ({ supportingContent }: Props) => {
    return (
        
        <ul className={styles.supportingContentNavList}>
            {supportingContent.map((x, i) => {
                const parsed = parseSupportingContentItem(x);
                const isFirstItem = i === 0;
                const [isExpanded, setIsExpanded] = useState(isFirstItem);

                // Toggle the expansion state when the element is clicked
                const handleToggle = () => {
                    setIsExpanded(!isExpanded);
                };
                return (
                    <li className={`${styles.supportingContentItem} ${!isExpanded ? styles.expanded : ""}`} onClick={handleToggle}>
                        {/* <h4 className={styles.supportingContentItemHeader}>{parsed.title}</h4> */}
                        <div className={styles.headerContainer}>
                            <h4 className={styles.supportingContentItemHeader}>{"Citation"+(i+1)}</h4>
                            <span className={`${styles.expandIcon} ${isExpanded ? styles.collapsed : ""}`} />
                        </div>
                        <p className={styles.supportingContentItemText}>{parsed.content}</p>
                    </li>
                );
            })}
            <ChatBubble />
        </ul>
    );
};
