import { useRef, useState, useEffect } from "react";
import React from "react";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton } from "@fluentui/react";
import { SparkleFilled } from "@fluentui/react-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faTimes } from "@fortawesome/free-solid-svg-icons";
import styles from "./Chat.module.css";

import { chatApi, Approaches, AskResponse, ChatRequest, ChatTurn } from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import MetadataList from "../../components/MetaDataList/metadatalist";
import DropList from "../../components/DropList/dropdownlist";
import RatingComponent from "../../components/ratingmessage/ratingmessage";
import GaugeComponent from "../../components/GaugeChart/gauge_chart";
import ChatBubble from "../../components/ChatBubble/chatbubble";
import axios from "axios";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { Link, useHref, useNavigate } from "react-router-dom";

interface ChatData {
    guid: string;
    chat: string;
    time: string;
    description: string;
    chatTime: string;
}
interface ListChatHistoryProps {
    updateChatMessageObject: (uuid: string) => void;
}

export function ListChatHistory({ updateChatMessageObject }: ListChatHistoryProps) {
    const [chatData, setChatData] = useState<ChatData[]>([]);
    const corsProxyUrl = "https://cors-anywhere.herokuapp.com/"; // CORS proxy service URL
    //  const url = `http://54.172.137.151:81/api/Chat/ListChatHistory`;
    const url = `https://botchatapi.azurewebsites.net/api/Chat/ListChatHistory`;

    const fetchChatData = async () => {
        const res = await axios.get(url);
        setChatData(res.data);
    };
    fetchChatData();

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);

        const year = date.getFullYear();
        const month = (1 + date.getMonth()).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");

        // 12-hour time format
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'

        // timezone abbreviation
        const options: Intl.DateTimeFormatOptions = { timeZoneName: "short" };
        const str = new Intl.DateTimeFormat("en-US", options).format(date);
        const timeZone = str.split(" ").pop();

        return `${month}/${day}/${year} ${hours}:${minutes} ${ampm} ${timeZone}`;
    };

    useEffect(() => {
        // const intervalId = setInterval(fetchChatData, 10000);
        // return () => clearInterval(intervalId);
    }, []);
    const handleClick = (guid: string) => {
        updateChatMessageObject(guid);
    };
    // debugger
    return (
        <>
            <div className={styles.metadatalist}>
                <div className={styles.navmetadatalist}>
                    {/* <div className="h-16 border-b border-b-slate-200 py-4">
      </div> */}
                    <div className={styles.fontmetadata}>Dialog History</div>
                </div>
                <div className={styles.historyclass2}>
                    <div className={styles.historyclass4}>
                        {" "}
                        {/* Apply scroll styles to this container */}
                        <ul>
                            {chatData.map((chat: ChatData, index: number) => (
                                <li
                                    key={chat.guid}
                                    className={styles.historyclass3}
                                    style={{ borderBottom: index !== chatData.length - 1 ? "1px solid #2a2a2b" : "none" }}
                                >
                                    <div className={styles.historyclass5}>
                                        {/* <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                                            <path d="M20.25 0h-16.5c-1.517 0-2.75 1.233-2.75 2.75v14.5c0 1.517 1.233 2.75 2.75 2.75h8.883l4.867 4.867v-4.867h2.5c1.517 0 2.75-1.233 2.75-2.75v-11.5c0-1.517-1.233-2.75-2.75-2.75zm-15.5 2.75c0-.414.336-.75.75-.75h16.5c.414 0 .75.336.75.75v11.5c0 .414-.336.75-.75.75h-2.5v3.5l-3.5-3.5h-10.5c-.414 0-.75-.336-.75-.75v-11.5z" />
                                        </svg> */}
                                        <img
                                            src="/comment.png"
                                            alt="Chat History"
                                            style={{ width: "20px", height: "20px", marginTop: "2px", objectFit: "cover" }}
                                        />
                                    </div>
                                    <div className={styles.historyclass6} onClick={() => handleClick(chat.guid)}>
                                        {/* {chat.chat.replace("[[", "").slice(0, 35)}... */}
                                        {formatDate(chat.time) + ", "} {chat.guid}
                                        {/* <br />
                                        {chat.guid} */}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

const Chat = () => {
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [retrieveCount, setRetrieveCount] = useState<number>(3);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(false);

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: AskResponse][]>([]);
    const [history, setHistory] = useState<[string, string][]>([]);
    const [questionarray, setQuestionTime] = useState<[string, string][]>([]);
    const [singlehistory, setsingleHistory] = useState<[string, string][]>([]);

    const [citations, setCitations] = useState<[string[]][]>([]);
    const [HistoryResponse, setResponse] = useState<AskResponse>();
    const [gresult, setGResult] = useState<number>(0.5);
    const [gresultarray, setGResultarray] = useState<number[]>([]);
    const [rightPanelCollapsed, setRightPanelCollapsed] = useState(false);
    const [leftPanelCollapsed, setLeftPanelCollapsed] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;
        const qTime = formatDateTime();

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        try {
            const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
            const request: ChatRequest = {
                history: [...history, { user: question, bot: undefined }],
                approach: Approaches.ReadRetrieveRead,
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    semanticRanker: useSemanticRanker,
                    semanticCaptions: useSemanticCaptions,
                    suggestFollowupQuestions: useSuggestFollowupQuestions
                }
            };
            const result = await chatApi(request);
            setAnswers([...answers, [question, result]]);
            const ans = result.answer;
            const ATime = formatDateTime();
            setsingleHistory([...singlehistory, [question, ans]]);
            setQuestionTime([...questionarray, [qTime, ATime]]);
            setCitations([...citations, [result.data_points]]);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const formatDateTime = () => {
        const currentDateTime = new Date();
        const formattedDate = currentDateTime.toLocaleDateString();
        const formattedTime = currentDateTime.toLocaleTimeString();
        return `${formattedDate} ${formattedTime}`;
    };

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
        setQuestionTime([]);
    };

    async function updateChatMessageObject(guid: string): Promise<void> {
        try {
            debugger;
            //   const response = await axios.post<ChatData>("http://54.172.137.151:81/api/Chat/GetChatHistory", { guid });
            const response = await axios.post<ChatData>("https://botchatapi.azurewebsites.net/api/Chat/GetChatHistory", { guid });
            const chatResponse: ChatData = response.data;
            // const newMessage: ChatMessage = {
            //   type: 'userMessage',
            //   message: chatResponse.chat,
            //   sourceDocs: pendingSourceDocs,
            //   id: uuidv4(),
            // };

            setQuestionTime([]);
            const data = JSON.parse(chatResponse.chat);
            const citation = JSON.parse(chatResponse.description);
            const QAtime = JSON.parse(chatResponse.chatTime);

            let QA: [string, string][] = [];

            if (QAtime !== null) {
                for (let i = 0; i < QAtime.length; i++) {
                    const element = QAtime[i];
                    for (let j = 0; j < element.length; j++) {
                        const aTime = element[j];
                        const qTime = element[j - 1];

                        if (j % 2 !== 0) {
                            // setResponse(Response)
                            QA.push([qTime, aTime]);
                        }
                    }
                }
            } else {
                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    for (let j = 0; j < element.length; j++) {
                        // const aTime = element[j];
                        // const qTime = element[j - 1];

                        if (j % 2 !== 0) {
                            // setResponse(Response)
                            QA.push([formatDateTime(), formatDateTime()]);
                        }
                    }
                }
            }

            setQuestionTime(QA);

            // if(data.length>messagecount){
            //   const element1  = data(messagecount);
            //   const outp = element1[1];
            //   const outp1 = element1[0];

            //   MessageCallapi(outp,outp1);
            //   messagecount +=1;
            // }

            let historicist: [string, AskResponse][] = []; // Initialize as an empty array
            let PDF: [string, string][] = [];
            lastQuestionRef.current = "Test";
            debugger;
            let citationvalue: string[][] = [];
            if (citation.length > 0) {
                citationvalue = citation[0];
            }

            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                let citationvalue1: string[] = [];
                if (citationvalue.length > i) {
                    citationvalue1 = citationvalue[i];
                }

                for (let j = 0; j < element.length; j++) {
                    const outp = element[j];
                    const outp1 = element[j - 1];

                    if (j % 2 !== 0) {
                        // messagecount += 1;
                        // MessageCall(outp1);

                        const askResponse: AskResponse = {
                            answer: outp,
                            thoughts: null,
                            data_points: citationvalue1,
                            error: undefined
                        };

                        // setResponse(Response)
                        PDF.push([outp1, outp]);
                        historicist.push([outp1, askResponse]);
                    } else {
                    }
                }
            }

            setsingleHistory(PDF);

            setAnswers(historicist);

            const params: string[] = JSON.parse(chatResponse.chat);

            //   const modifiedInputString = data2.replace("][", "],[");
            // const jsonArray = JSON.parse(`[${modifiedInputString}]`);

            // MessageCall(chatResponse.chat);

            // Home("Message")
        } catch (error) {
            console.error(error);
        }
    }

    interface DropListItem {
        id: number;
        uuid: string;
        label: string;
        value: string;
    }

    const items = [
        { id: 1, uuid: "df84ce8f-e2cd-427c-85b5-2679ce0f2b51", label: "LinkedIn", value: "linkedin" },
        {
            id: 2,
            uuid: "63625b4c-c8dc-48f0-8e32-241aa1c4e37c",
            label: "Corporate Governance",
            value: "corporategovernance"
        }
        // {
        //     id: 1,
        //     uuid: "32d85331-e25e-4f87-acf7-c67ada71b788",
        //     label: "Pedigree Manuals"
        // }
        // {
        //   id: 4,
        //   uuid: "cb3a924a-5ec0-4453-bc09-0575e11b514e",
        //   label: "d-Wise Blur User Manuals",
        // },
    ];

    const [selectedRootObject, setSelectedRootObject] = useState<string>("df84ce8f-e2cd-427c-85b5-2679ce0f2b51");

    // setSelectedRootObject("32d85331-e25e-4f87-acf7-c67ada71b788");

    const [selectedRootLabel, setSelectedRootLabel] = useState<string>("LinkedIn");

    const [value, setValue] = useState<string>("linkedin");
    //SelectedUUID("2279b2a0-91af-4f93-ab64-106cbb91dcdd");

    const handleDropListSelect = (selectedItem: DropListItem) => {
        setSelectedRootObject(selectedItem.uuid);
        // SelectedUUID(selectedItem.uuid);
        setSelectedRootLabel(selectedItem.label);
        setValue(selectedItem.value);

        sendValueToBackend(selectedItem.value);
        clearChat();
        //handleLabelChange(selectedItem.label);
    };

    const sendValueToBackend = (value: string) => {
        fetch("/knowledgeselection", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },

            body: JSON.stringify({ value })
        })
            .then(response => response.json())
            .then(data => {
                // Handle the response from the backend if needed
                console.log(data);
            })
            .catch(error => {
                // Handle any errors that occurred during the request
                console.error("Error:", error);
            });
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "3"));
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };

    const onShowCitation = (citation: string, index: number) => {
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }

        setSelectedAnswer(index);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    const formatDateTime1 = () => {
        const currentDateTime = new Date();
        const formattedDate = currentDateTime.toLocaleDateString();
        const formattedTime = currentDateTime.toLocaleTimeString();

        return `${formattedDate} ${formattedTime}`;
    };

    const navigate = useNavigate();
    const handleLogout = () => {
        // Perform logout logic here
        setIsLoggedIn(false);
        // Navigate to the LoginPopup component
        navigate("/");
    };
    // Prevent going back or forward if not logged in
    window.onpopstate = () => {
        if (!isLoggedIn && navigate?.length === 1) {
            navigate("/");
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.commandsContainer}>
                {/* <ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} /> */}
                {/* <SettingsButton className={styles.commandButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} /> */}
            </div>

            <div className={styles.chatRoot}>
                {!leftPanelCollapsed && (
                    <div className={styles.headKnowleadgebase}>
                        <nav className={styles.DialogTest}>
                            <h2 className={styles.DialogFont}>Knowledgebases</h2>
                        </nav>
                        <DropList
                            items={items}
                            storageKey="selectedKnowledgebase"
                            onItemSelect={handleDropListSelect}
                            selectedLabel={selectedRootLabel} //  selectedLabel={selectedLabel}
                        />

                        <div className={styles.metadatalist}>
                            <div className={styles.navmetadatalist}>
                                <div className={styles.fontmetadata}>{`Metadata: ${selectedRootLabel}`}</div>
                                <MetadataList rootObjectName={selectedRootObject} />
                            </div>

                            <ListChatHistory updateChatMessageObject={updateChatMessageObject} />
                            <GaugeComponent gaugevalue={gresult} />
                        </div>
                    </div>
                )}
                <button className="flex items-center justify-center bg-gray-200 text-gray-800 p-2" onClick={() => setLeftPanelCollapsed(!leftPanelCollapsed)}>
                    {leftPanelCollapsed ? <FiChevronRight /> : <FiChevronLeft />}
                </button>
                <div className={styles.chatContainer}>
                    {/* <div className=""> */}
                    {/* <div className={styles.headDialog1}>
                        <nav className={styles.DialogTest}>
                            <h2 className={styles.DialogFont}>Dialog</h2>Hi, I am Eve. Your AI Assitant.
                        </nav>
                    </div> */}
                    <div className={styles.headDialog1}>
                        <div className={styles.headDialog1}>
                            <div>
                                <div className={styles.DialogTest}>
                                    <div className={styles.DialogFont}>Dialog</div>
                                </div>
                                <div className={styles.DialogTest}>
                                    <div className={styles.DialogText}>Hi, I am Eve. Your AI Assistant.</div>
                                </div>
                            </div>
                            <div>
                                <button className={styles.logoutButton} onClick={handleLogout}>
                                    Logout
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}

                    <div className={styles.chatContainer1}>
                        {!lastQuestionRef.current ? (
                            <div className={styles.chatEmptyState}>
                                <img src="/Tu Logo.png" aria-label="Chat logo" height="120" />
                                {/* <SparkleFilled fontSize={"120px"} primaryFill={"rgba(115, 118, 225, 1)"} aria-hidden="true" aria-label="Chat logo" /> */}
                                <h1 className={styles.chatEmptyStateTitle}>Chat with your data</h1>
                                <h2 className={styles.chatEmptyStateSubtitle}>Ask anything or try an example</h2>
                                <ExampleList onExampleClicked={onExampleClicked} />
                            </div>
                        ) : (
                            <div className={styles.chatMessageStream}>
                                {answers.map((answer, index) => (
                                    <div key={index}>
                                        <UserChatMessage message={"Q" + (index + 1) + ". " + answer[0]} time={questionarray[index][0]} />
                                        <div className={styles.chatMessageGpt}>
                                            <Answer
                                                key={index}
                                                listvalue={index + 1}
                                                answer={answer[1]}
                                                isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                onCitationClicked={c => onShowCitation(c, index)}
                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                                ATime={questionarray[index][1]}
                                            />
                                        </div>
                                        <div className={styles.answerRatings}>
                                            <RatingComponent
                                                ratings={[]}
                                                handleRatings={function (rating: string[]): void {
                                                    throw new Error("Function not implemented.");
                                                }}
                                                index={0}
                                                ratings2={[]}
                                            />
                                            {/* handleRatings={handleRatings}
                              ratings={ratings}
                              index={(indexCT/2)-1}
                              ratings2={ratings2} */}
                                            <button className={styles.citationbutton} onClick={() => onShowCitation("", index)}>
                                                {"Citations"}
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                {isLoading && (
                                    <>
                                        <UserChatMessage message={lastQuestionRef.current} time={formatDateTime1()} />
                                        <div className={styles.chatMessageGptMinWidth}>
                                            <AnswerLoading />
                                        </div>
                                        {/* <div className={styles.answerRatings}>
                                            Satisfaction Rating&nbsp;
                                            <button onClick={() => true} className={styles.Ratingsbutton}>
                                                <i className={styles.Ratingsicon} aria-hidden="true"></i>
                                            </button>
                                            <button className={styles.citationbutton} onClick={() => ""}>
                                                {"Show Citations"}
                                            </button>
                                        </div> */}
                                    </>
                                )}
                                {error ? (
                                    <>
                                        <UserChatMessage message={lastQuestionRef.current} time={formatDateTime1()} />
                                        <div className={styles.chatMessageGptMinWidth}>
                                            <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                        </div>
                                    </>
                                ) : null}
                                <div ref={chatMessageStreamEnd} />
                            </div>
                        )}
                    </div>
                    <div className={styles.center100}>
                        <div className={styles.questioncenter100}>
                            <nav className={styles.navquestioncenter100}>
                                <div className={styles.headquestioncenter100}>Question</div>
                            </nav>
                        </div>
                    </div>

                    <div className={styles.chatInput}>
                        <QuestionInput
                            clearOnSend
                            placeholder={isLoading ? "Waiting for response..." : "Type your questions here."}
                            disabled={isLoading}
                            onSend={question => makeApiRequest(question)}
                            clearChat={clearChat}
                            citation={citations}
                            answers={answers}
                            chatTime={questionarray}
                            history={singlehistory}
                            selectedRootLabel={selectedRootLabel}
                            GaugeValue={gvalue => setGResult(gvalue)}
                        />
                    </div>
                </div>

                <div className={styles.footer}>
                    <div>© 2023 TechUnity, Inc. All Rights Reserved.</div>
                </div>
                <button className="flex items-center justify-center bg-gray-200 text-gray-800 p-2" onClick={() => setRightPanelCollapsed(!rightPanelCollapsed)}>
                    {" "}
                    {rightPanelCollapsed ? <FiChevronLeft /> : <FiChevronRight />}
                </button>
                {!rightPanelCollapsed && activeAnalysisPanelTab && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        answer={answers[selectedAnswer][1]}
                        activeTab={activeAnalysisPanelTab}
                    />
                )}
                {!rightPanelCollapsed && activeAnalysisPanelTab == undefined && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        activeTab={AnalysisPanelTabs.ThoughtProcessTab}
                        answer={{
                            answer: "",
                            thoughts: null,
                            data_points: [],
                            error: undefined
                        }} // answer={answers[selectedAnswer][1]}
                        // activeTab={activeAnalysisPanelTab}
                    />
                )}
                {/* {!rightPanelCollapsed && <ChatBubble />} */}
                <Panel
                    headerText="Configure answer generation"
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsConfigPanelOpen(false)}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    <TextField
                        className={styles.chatSettingsSeparator}
                        defaultValue={promptTemplate}
                        label="Override prompt template"
                        multiline
                        autoAdjustHeight
                        onChange={onPromptTemplateChange}
                    />

                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Retrieve this many documents from search:"
                        min={1}
                        max={50}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                    />
                    <TextField className={styles.chatSettingsSeparator} label="Exclude category" onChange={onExcludeCategoryChanged} />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticRanker}
                        label="Use semantic ranker for retrieval"
                        onChange={onUseSemanticRankerChange}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticCaptions}
                        label="Use query-contextual summaries instead of whole documents"
                        onChange={onUseSemanticCaptionsChange}
                        disabled={!useSemanticRanker}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSuggestFollowupQuestions}
                        label="Suggest follow-up questions"
                        onChange={onUseSuggestFollowupQuestionsChange}
                    />
                </Panel>
            </div>
        </div>
    );
};

export default Chat;
