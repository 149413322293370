import { ChangeEvent, useEffect, useState } from "react";
import { Stack, TextField } from "@fluentui/react";
import { Send28Filled } from "@fluentui/react-icons";
import Modal from "react-modal";
import styles from "./QuestionInput.module.css";
import PdfGenerator from "../../components/PdfGenerators/PdfGenerators";
import { AskResponse } from "../../api";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { UpdateHistory } from "../../api/chathistory";
import Sentiment from "sentiment";

const sentiment = new Sentiment();

interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    clearChat: () => void;
    answers: [user: string, response: AskResponse][];
    history: [user: string, answer: string][];
    chatTime: [qTime: string, aTime: string][];
    citation: [string[]][];
    selectedRootLabel: string;
    GaugeValue: (gvalue: number) => void;
}

export const QuestionInput = ({
    onSend,
    disabled,
    placeholder,
    clearOnSend,
    clearChat,
    answers,
    history,
    chatTime,
    citation,
    selectedRootLabel,
    GaugeValue
}: Props) => {
    const [question, setQuestion] = useState<string>("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);

    const [StartTime, setStartTime] = useState<string>("");
    const [EndTime, setEndTime] = useState<string>("");
    const [sessionTime, setSessionTime] = useState(0);
    const [ratings1, setRatings1] = useState<string>("");
    const [emailError, setEmailError] = useState("");

    const [result, setResult] = useState<number>(0);
    const [calculated, setCalcuated] = useState<number | null>(null);

    const [gresultarray, setGResultarray] = useState<number[]>([]);

    const analyzeSentiment = () => {
        const { score } = sentiment.analyze(question);

        let Calc = (score - -4) / (4 - -4);

        setCalcuated(Calc);
        debugger;
        console.log(Calc);
        setResult(Calc);

        setGResultarray([...gresultarray, Calc]);

        // Calculate the average
        // const average = gresultarray.reduce((sum, value) => sum + value, 0) / gresultarray.length;
        // console.log("Average:", average);

        // GaugeValue(average);

  
    };
    useEffect(() => {
        // Calculate the average whenever gresultarray changes
        const average = gresultarray.reduce((sum, value) => sum + value, 0) / gresultarray.length;
        console.log("Average:", average);

        if(gresultarray.length==0){
            GaugeValue(0.5);
        }else{
            GaugeValue(average);
        }
       
    }, [gresultarray]);

    const formatDateTime = () => {
        const currentDateTime = new Date();
        const formattedDate = currentDateTime.toLocaleDateString();
        const formattedTime = currentDateTime.toLocaleTimeString();
        return `${formattedDate} ${formattedTime}`;
    };

    const ChatDateTime = () => {
        const currentDateTime = new Date();
        const year = currentDateTime.getFullYear();
        const month = String(currentDateTime.getMonth() + 1).padStart(2, "0");
        const day = String(currentDateTime.getDate()).padStart(2, "0");
        const hours = String(currentDateTime.getHours()).padStart(2, "0");
        const minutes = String(currentDateTime.getMinutes()).padStart(2, "0");
        const seconds = String(currentDateTime.getSeconds()).padStart(2, "0");
        const milliseconds = String(currentDateTime.getMilliseconds()).padStart(3, "0");

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    };
    const formatTime = (time: number) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;

        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    };

    const arrayBufferToBuffer = (ab: ArrayBuffer) => {
        let buffer = new Buffer(ab.byteLength);
        let view = new Uint8Array(ab);
        for (let i = 0; i < buffer.length; ++i) {
            buffer[i] = view[i];
        }
        return buffer;
    };

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newEmail = event.target.value;
        setEmail(newEmail);

        // Very simple email validation.
        // You might want to use a more complete validation logic.
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidEmail(emailRegex.test(newEmail));
    };
    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        analyzeSentiment();

        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const ChatSubmitWrapper = async (email: string) => {

        setEmailError("");

        let isValid = true;

        if (!email) {
            setEmailError("*Email Address is required.");
            isValid = false;
        } else if (!isValidEmail) {
            setEmailError("*Please enter a valid email address.");
            isValid = false;
        }else{
            let EndTimeSession = formatDateTime();
            setEndTime(EndTimeSession);
    
            debugger;
    
            const startTime = new Date(StartTime);
            const endTime = new Date(EndTimeSession);
    
            const timeDifference = endTime.getTime() - startTime.getTime();
    
            try {
                const SessionTimer = formatTime(sessionTime);
                const url = `https://transcriptionchatbot.azurewebsites.net/api/transcriptionhttptrigger?mail=`;
    
                // const formattedHistory = [];
    
                // for (let i = 0; i < history.length; i++) {
                //     const [user, answer] = history[i];
                //     formattedHistory.push(`["${user}", "${answer}"]`);
                // }
    
                // const formattedString = `[${formattedHistory.join(", ")}]`;
    
                const encodedString = encodeURIComponent(JSON.stringify(history));
    
                const url2 = `&history=` + encodedString;
                const url3 = `&topic=` + selectedRootLabel;
                const res = await axios.get(url + email + url2 + url3);
                //   const messageTimer = [];
    
                //   for (let i = 0; i < messages.length; i++) {
    
                //     const messageData = messages[i].msgDateTime;
                //     messageTimer.push(messageData);
    
                //   }
    
                //  const pdfData = await PdfGenerator(history,selectedRootLabel,timeDifference,messageTimer);
    
                //  const pdfData = await PdfGenerator(answers,selectedRootLabel,timeDifference);
                debugger;
                // const buffer = arrayBufferToBuffer(pdfData.pdfBlob);
    
                //   const response = await fetch('api/mail', {
    
                //     method: 'POST',
                //     headers: { 'Content-Type': 'application/json' },
                //     body: JSON.stringify({ receiver: email, filename: selectedRootLabel }),
                //   });
                //   if (response.status==200) {
                //     const data = await response.json();
                //     console.log('Email sent successfully', data);
                //   } else {
                //     console.error('Failed to send email');
                //   }
    
                //   setModalIsOpen(false);
            } catch (error) {
                setModalIsOpen(false);
                clearChat();
                setEmail("");
                console.error("Failed to submit chat", error);
            }
            setModalIsOpen(false);
            clearChat();
            setEmail("");
            //UpdateHistory(uuidv4(), JSON.stringify(history),ratings1);
            //  setRatings1('');
            // handleReset();
        }

       
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };
    const updatedResponse = async () => {
        debugger;
    };

    const NoTrans = () => {
        debugger;
        UpdateHistory(uuidv4(), JSON.stringify(history), ratings1, JSON.stringify(citation), ChatDateTime(), JSON.stringify(chatTime));
        updatedResponse;

        setModalIsOpen(false);
        clearChat();
        setEmail("");
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= 1000) {
            setQuestion(newValue);
        }
    };

    const sendQuestionDisabled = disabled || !question.trim();

    const closeModal = () => {
        setModalIsOpen(false);
        setEmail(""); // Reset the email state to an empty string
        setEmailError(""); // Optionally, reset the email error state as well if you're using it
      };

    return (
        <Stack horizontal className={styles.questionInputContainer}>
            <TextField
                className={styles.questionInputTextArea}
                placeholder={placeholder}
                resizable={false}
                borderless
                rows={1}
                value={question}
                onChange={onQuestionChange}
                onKeyDown={onEnterPress}
            />
            <div className={styles.questionInputButtonsContainer}>
                <div
                    className={`${styles.questionInputSendButton} ${sendQuestionDisabled ? styles.questionInputSendButtonDisabled : ""}`}
                    aria-label="Ask question button"
                    onClick={sendQuestion}
                >
                    <button type="submit" className={styles.generatebutton}>
                        <svg viewBox="0 0 20 20" className={styles.svgicon} xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                        </svg>
                    </button>{" "}
                    <button type="reset" className={styles.generatebuttonCancel} onClick={clearChat}>
                        Clear
                    </button>{" "}
                    <button type="button" className={styles.generatebuttonEnd} onClick={() => setModalIsOpen(true)}>
                        End
                    </button>
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={() => setModalIsOpen(false)}
                        style={{
                            content: {
                                top: "50%",
                                left: "50%",
                                right: "auto",
                                bottom: "auto",
                                marginRight: "-50%",
                                transform: "translate(-50%, -50%)",
                                height: "250px",
                                width: "500px"
                            }
                        }}
                    >
                        <div className={styles.endMEssage1}>Dialog is closing. Provide Email to receive transcript.</div>
                        <div>
                            <input type="email" value={email} onChange={handleEmailChange} className={styles.endMEssage2} placeholder="Enter your email" />
                            {/* {!isValidEmail && <p className={styles.textred}>Please enter a valid email</p>} */}
                            <span className={styles.textred}>{emailError}</span>
                        </div>

                        <div className={styles.endMEssage3}>
                            <button
                                // onClick={ChatSubmitWrapper}
                                onClick={() => ChatSubmitWrapper(email)}
                                className={styles.endMEssage4}
                            >
                                Transcript
                            </button>{" "}
                            <button
                                onClick={NoTrans}
                                // onClick={handleReset}
                                className={styles.endMEssage4}
                            >
                                No Transcipt
                            </button>{" "}
                            <button onClick={closeModal} className={styles.endMEssage5}>
                                Return
                            </button>
                        </div>
                    </Modal>
                    {/* <Send28Filled primaryFill="rgba(115, 118, 225, 1)" /> */}
                </div>
            </div>
        </Stack>
    );
};
