import React from "react";
import dynamic from "next/dynamic";
import styles from "./gauge-chart.module.css";

// Dynamically import the GaugeChart component
const GaugeChart = dynamic(() => import("react-gauge-chart"), { ssr: false });

interface GaugeComponenttProps {
    gaugevalue?: number ;
}

const GaugeComponent: React.FC<GaugeComponenttProps> = ({gaugevalue}) => {
    const gaugeValue = gaugevalue ;
    const gaugeChartId = "gauge-chart1";

    const GaugeSize = {
        width: "275px", // Set the desired width
        height: "100px" // Set the desired height
    };

    const colors = [
        "#FF0000",
        /*"#FF3300",*/
        "#FF6600",
        /*"#FF9900",*/
        "#FFCC00",
        "#FFFF00",
        "#CCFF00",
        /*"#99FF00",*/
        "#66FF00",
        /*"#33FF00",*/
        "#00FF00"
    ];

    const arcLengths = Array(5).fill(1 / 5); // fill an array with 11 equal segments

    // const getLabel = (value: number) => {
    //   if (value < 0.5) {
    //     return "Negative";
    //   } else if (value === 0.5) {
    //     return "Neutral";
    //   } else {
    //     return "Positive";
    //   }
    // };

    return (
        <div className={styles.gauge}>
            <div className={styles.gaugenav}>
                <div className={styles.fontgauge}>{`Dialog Temperature`}</div>
                <div className={styles.gaugesize}>
                    <GaugeChart
                        id={gaugeChartId}
                        percent={gaugeValue}
                        hideText={true}
                        nrOfLevels={5}
                        colors={colors}
                        arcsLength={arcLengths}
                        textColor={"#000000"}
                        style={GaugeSize}
                    />
                    <div className={styles.gaugeemoji}>
                        <div className={styles.gaugeangry}>
                            <img src="/angry.png" alt="Angry" style={{ width: "23px", height: "23px", objectFit: "cover" }} />
                        </div>
                        <div className={styles.gaugeneutral}>
                            <img src="/neutral.png" alt="Neutral" style={{ width: "23px", height: "23px", objectFit: "cover" }} />
                        </div>
                        <div className={styles.gaugeHappy}>
                            <img src="/happy.png" alt="Happy" style={{ width: "23px", height: "23px", objectFit: "cover" }} />
                            {/* getLabel(gaugeValue)*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GaugeComponent;
