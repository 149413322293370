import React, { useState } from "react";
import Modal from "react-modal";
import styles from "./ratingmessage.module.css";
//import axios from "axios";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faStar, faTimes } from "@fortawesome/free-solid-svg-icons";

type Rating = "Actionable" | "Workable" | "Okay" | "Limited" | "Useless";
// interface StarProps {
//   filled: boolean;
// }

interface Ratingmessageprops {
    ratings: string[];
    handleRatings: (rating: string[]) => void;
    index: number;
    ratings2: [string, string][];
}
debugger;
export default function RatingComponent({ ratings, handleRatings, index, ratings2 }: Ratingmessageprops) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [rating, setRating] = useState<Rating>("Okay");
    const [comment, setComment] = useState("");
    const [selectedImage, setSelectedImage] = useState("/R.png");

    const imageMap: Record<Rating, string> = {
        Useless: "/R1.png",
        Limited: "/R2.png",
        Okay: "/R3.png",
        Workable: "/R4.png",
        Actionable: "/R5.png"
    };
    debugger;
    console.log(index);

    //const [ratingsfinal, setRatingsfinal] = useState<string[]>([]);
    //const [sendratings, setSendRatings] = useState<string[]>([]);

    ratings = [];

    let Rating1: string[];

    if (ratings2.length > 0) {
        for (let i = 0; i < ratings2.length; i++) {
            Rating1 = ratings2[i];
            if (parseInt(Rating1[2]) == index) {
                ratings = Rating1;
            } else {
                / empty /;
            }
        }
    }

    // Method to handle select change
    const handleSelectChange = (value: Rating) => {
        setRating(value);
        setSelectedImage(imageMap[value]); // Update image based on selected rating
      
    };

    const handleSubmit = async () => {

        setSelectedImage(imageMap[rating]); 
        // Create an object to store the rating and comment
        //const ratingData = {
        //  rating,
        //  comment,
        //};
        // Save the rating and comment as JSON
        //const jsonData = JSON.stringify(ratingData);

        // const ratings_1 = [rating, comment, "" + index];
        // handleRatings([rating, comment, "" + index]);
        // ratings = ratings_1;
        // Show the rating and comment in the console

        // Reset the rating and comment fields
        setModalIsOpen(false);
    };

    // const Star: React.FC<StarProps> = ({ filled }) => {
    //   return (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       viewBox="0 0 24 24"
    //       fill={filled ? "white" : "yellow"}
    //       width="16"
    //       height="16"
    //       className="feather feather-star"
    //     >
    //       <path d="M12 2 L15.09 8.45 L22 9.27 L17 14 L18.18 21 L12 17 L5.82 21 L7 14 L2 9.27 L8.91 8.45 L12 2 Z" />
    //     </svg>
    //   );
    // };
    return (
        <div className={styles.ratings}>
            Rating&nbsp;
            <button onClick={() => setModalIsOpen(true)} className={styles.ratingsbutton}>
                <img
                    src={selectedImage} // Use the state value here
                    alt="Rating"
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
            </button>
            {ratings.length > 0 && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={() => setModalIsOpen(false)}
                    style={{
                        content: {
                            top: "50%",
                            left: "50%",
                            right: "auto",
                            bottom: "auto",
                            marginRight: "-50%",
                            transform: "translate(-50%, -50%)",
                            height: "225px",
                            width: "350px",
                            borderRadius: "15px"
                        }
                    }}
                >
                    <div onClick={() => setModalIsOpen(false)} className={styles.ratingsmodal}>
                        <img
                            src={selectedImage} // Use the state value here
                            alt="Rating"
                            style={{ width: "100%", height: "100%", objectFit: "cover" }}
                        />
                    </div>
                    <select value={ratings[0]} onChange={e => handleSelectChange(e.target.value as Rating)} className={styles.ratingsselect}>
                        <option value="Actionable">Actionable</option>
                        <option value="Workable">Workable</option>
                        <option value="Okay">Okay</option>
                        <option value="Limited">Limited</option>
                        <option value="Useless">Useless</option>
                    </select>
                    <textarea
                        value={ratings[1]}
                        onChange={e => setComment(e.target.value)}
                        placeholder="Leave your comment here..."
                        className={styles.ratingsTextarea}
                    />
                    <div className={styles.modal}>
                        <button onClick={handleSubmit} className={styles.modalbutton}>
                            Submit
                        </button>{" "}
                        <button onClick={() => setModalIsOpen(false)} className={styles.modalbutton}>
                            Cancel
                        </button>
                    </div>
                </Modal>
            )}
            {ratings.length == 0 && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={() => setModalIsOpen(false)}
                    style={{
                        content: {
                            top: "50%",
                            left: "50%",
                            right: "auto",
                            bottom: "auto",
                            marginRight: "-50%",
                            transform: "translate(-50%, -50%)",
                            height: "225px",
                            width: "350px",
                            borderRadius: "15px"
                        }
                    }}
                >
                    {/* <button onClick={() => setModalIsOpen(false)} className={styles.ratingsmodal}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button> */}
                    <select value={rating} onChange={e => handleSelectChange(e.target.value as Rating)} className={styles.ratingsselect}>
                        <option value="Actionable">Actionable</option>
                        <option value="Workable">Workable</option>
                        <option value="Okay">Okay</option>
                        <option value="Limited">Limited</option>
                        <option value="Useless">Useless</option>
                    </select>
                    <textarea
                        value={comment}
                        onChange={e => setComment(e.target.value)}
                        placeholder="Leave your comment here..."
                        className={styles.ratingsTextarea}
                    />
                    <div className={styles.modal}>
                        <button onClick={handleSubmit} className={styles.modalbutton}>
                            Submit
                        </button>{" "}
                        <button onClick={() => setModalIsOpen(false)} className={styles.modalbutton}>
                            Cancel
                        </button>
                    </div>
                </Modal>
            )}
        </div>
    );
}
