import React, { useState } from "react";
import styles from "./charbubble.module.css";

// const BubbleButton = styled.button`
//     position: fixed;
//     bottom: 40px;
//     right: 60px;
//     width: 200px;
//     height: 200px;
//     border-radius: 50%;
//     background-color: #0084ff;
//     border: none;
//     padding: 0;
//     box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
//     cursor: pointer;
//     outline: none;
//     overflow: hidden;
// `;

// const ChatWindow = styled.div`
//     position: fixed;
//     bottom: 190px;
//     right: 20px;
//     width: 300px;
//     height: 400px;
//     background-color: white;
//     border-radius: 8px;
//     box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
//     padding: 10px;
//     display: flex;
//     flex-direction: column;
// `;

const ChatBubble: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleBubbleClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <button className={styles.button} onClick={handleBubbleClick}>
                <img src="/anna.png" alt="Chat Avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                {/* Button content */}
            </button>
            {/* <BubbleButton onClick={handleBubbleClick}>
               
            </BubbleButton> */}

            {isOpen && <div className={styles.container}>Your chat component goes here...</div>}

            {/* {isOpen && <ChatWindow>Your chat component goes here...</ChatWindow>} */}
        </>
    );
};

export default ChatBubble;
